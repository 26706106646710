import React, { useState } from 'react';
import { Link } from 'gatsby';
import * as styles from '../../styles/contact.module.css';

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from 'react-icons/fa';

export default function Footer() {
  const [values, setValues] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className='footer_wrapper'>
      <div className='footer_inner'>
        <div className='footer_inner_content'>
          <div>
            <img src='/Logo.png' alt='Logo' />
            <p>Company Address</p>
          </div>
          <div className='footer_inner_sub_content'>
            <div>
              <p>company</p>
              <p>careers</p>
              <p>services</p>
              <p>blog</p>
            </div>
          </div>
          <div className='footer_inner_sub_content'>
            <p>contact us</p>
            <p>cookie preferences</p>
            <p>privacy policy</p>
            <p>terms of service</p>
          </div>

          <div>
            <p>Subscribe to know our latest blogs</p>
            <div className='footer_input'>
              <input
                type='text'
                placeholder='Your email address'
                name='email'
                value={values.email}
                onChange={handleChange}
              />

              <button className='footerbtn'>submit</button>
            </div>
            <div className={styles.social_icon}>
            <ul>
              <li>
                <Link to='/'>
                  <FaFacebook className={styles.fab} />
                </Link>
              </li>
              <li>
                <Link to='/'>
                  <FaTwitter className={styles.fab} />
                </Link>
              </li>
              <li>
                <Link to='/'>
                  <FaInstagram className={styles.fab} />
                </Link>
              </li>
              <li>
                <Link to='/'>
                  <FaYoutube className={styles.fab} />
                </Link>
              </li>
              <li>
                <Link to='/'>
                  <FaLinkedin className={styles.fab} />
                </Link>
              </li>
            </ul>
          </div>
          </div>
        </div>
          <p className='copyright'>All rights reserved © 2022 LabLamb Works</p>
      </div>
    </div>
  );
}
